import { ReactNode } from 'react';

import { ContentWrapper } from '@collab/atoms';

import styles from './ErrorView.module.css';

const ErrorView: React.FC<{
  heading: string;
  message: string;
  children: ReactNode;
}> = ({ heading, message, children }) => (
  <ContentWrapper>
    <div className={styles.errorContainer}>
      <h2 className="heading-2">
        Oops! <span className="text-secondary">{heading}</span>
      </h2>
      <p className="font-20 text-secondary pt-16">{message}</p>
      <div className="pt-24">{children}</div>
    </div>
  </ContentWrapper>
);

export default ErrorView;

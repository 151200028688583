import { useEffect } from 'react';

import { useUserContext } from '@collab/features/user';
import { UnauthorizedError } from '@collab/utils/api';
import { isAuthenticated } from '@collab/utils/session';

import useErrorHandler from './useErrorHandler';

const useUnauthorizedErrorListener = () => {
  const handleError = useErrorHandler();
  const { userStatus } = useUserContext();

  useEffect(() => {
    if (userStatus === 'logged-in' && !isAuthenticated()) {
      handleError(new UnauthorizedError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useUnauthorizedErrorListener;

import { useEffect, useState } from 'react';

import Link from './Link';

const HomeLink = () => {
  const [origin, setOrigin] = useState('');

  useEffect(() => {
    setOrigin(window.location.origin);
  }, []);

  return <Link href={origin} text="Home" />;
};

export default HomeLink;

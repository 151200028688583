import { Link as VCCLink } from '@vcc-www/buttons';

import { RedirectLink } from './types';

const Link: React.FC<RedirectLink> = ({ text, href }) => (
  <VCCLink
    extend={{ width: 'fit-content' }}
    arrow="right"
    trackEventAction="link|click"
    trackEventLabel={href}
    href={href}
    target="_self"
  >
    {text}
  </VCCLink>
);

export default Link;

import { Error404View } from '@collab/organisms';

import PageSeo from 'libs/seo/PageSeo';

const ErrorPage = () => (
  <>
    <PageSeo title="404" />
    <Error404View />
  </>
);

export default ErrorPage;

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import useUnauthorizedErrorListener from '@collab/libs/errors/useUnauthorizedErrorListener';
import { logException } from '@collab/utils/logger';

import ErrorView from './ErrorView';
import HomeLink from './HomeLink';
import Link from './Link';
import { Redirect, RedirectLink } from './types';

const Error404View: React.FC<{
  redirects?: Redirect[];
}> = ({ redirects = [] }) => {
  useUnauthorizedErrorListener();

  const { message, extraLink } = use404Error(redirects);

  return (
    <ErrorView heading="Error 404" message={message}>
      {extraLink && <Link {...extraLink} />}
      <HomeLink />
    </ErrorView>
  );
};

const use404Error = (redirects: Redirect[]) => {
  const { asPath } = useRouter();

  const [message, setErrorMessage] = useState('');
  const [extraLink, setExtraLink] = useState<RedirectLink | null>(null);

  const updateCustomError = async ({ pageName, newUrl, message }: Redirect) => {
    setErrorMessage(message ?? defaultMessage);

    try {
      setExtraLink({
        href: typeof newUrl === 'string' ? newUrl : await newUrl(),
        text: pageName,
      });
    } catch (e) {
      logException(e);
    }
  };

  useEffect(() => {
    const redirect = redirects.find(({ oldUrl }) => asPath.includes(oldUrl));

    if (redirect) {
      updateCustomError(redirect);
    } else {
      setErrorMessage(defaultMessage);
    }
  }, [asPath, redirects]);

  return { message, extraLink };
};

const defaultMessage =
  "We can't find the page you're looking for. You may have mistyped the address or the page may have been moved.";

export default Error404View;
